import * as React from "react";
import "../styles/globals.css";
import "./index.scss";

import { StaticImage } from "gatsby-plugin-image";

import {
	ViewMenuButton,
	ViewWinterMenuButton,
} from "../components/bookingButtons";

import NavbarWeb from "../components/navbarWeb";
import MobileNav from "../components/navbarMobile";
import Footer from "../components/footer";

import Carousel from "../components/menuCarousel";

const menuPage = () => {
	return (
		<main id="Menu">
			<NavbarWeb></NavbarWeb>
			<div className="mobileNavContainer">
				<div className="navLogo">
					<StaticImage
						src="../images/Kyoto-Brush-White.png"
						placeholder="blurred"
						width={346}
						height={720}
						formats={["auto", "webp", "avif"]}
						alt="Kyoto Garden Menu Page"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
				<MobileNav></MobileNav>
			</div>
			<header className="hero-banner">
				<h1>MENU</h1>
				<div className="image-container">
					<StaticImage
						src="../images/menu1.jpg"
						placeholder="tracedSVG"
						width={1250}
						height={400}
						formats={["auto", "webp", "avif"]}
						alt="Koshi sushi chef"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
			</header>

			<section className="menu-page-section">
				<p>Our updated menu as of 2024:</p>
				<ViewMenuButton></ViewMenuButton>
				<p>Winter at Kyoto 2024 Menu:</p>
				<ViewWinterMenuButton></ViewWinterMenuButton>
			</section>
			<div className="carousel-container">
				<Carousel></Carousel>
			</div>
			<Footer></Footer>
		</main>
	);
};

export default menuPage;

export const Head = () => (
	<>
		<title>Menu</title>
		<meta
			name="description"
			content="View our delicious menu at Kyoto Garden. Our expert chefs use only the freshest ingredients to create a range of dishes from classic sushi rolls to more traditional Japanese fare."
		/>
	</>
);
